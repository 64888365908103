import AOS from 'aos';
import 'magnific-popup';

var loaded = false;
document.addEventListener( 'musickitloaded', function () {
	if ( !loaded ) {
		loaded = true;
		let AmkPlaylists = [];
		let AmkAlbums = ['1508400421'];

		const sm = new SMEAppleMusic( {
			ae: {
				ae: '13d53b9ec1a982f4c75b5575d6c06f20d0071cf21f83f82eabbb8f7a14ed0a60',
				brand_id: '4398260',
				segment_id: '979812',
				activities: '{"actions":{"presave":37327},"mailing_list_optins":{"a0S1p00000USWfwEAH":37328}}'
			},
			am: {
				dev_token: '',
				save_mode: 'library',
				custom_playlist_name: '',
				resources: {
					playlists: AmkPlaylists,
					albums: AmkAlbums
				}
			},
			sf: {
				form: '62061',
				default_mailing_list: 'a0S1p00000USWfwEAH'
			},
			smf: {
				campaign_id: '62061',
				campaign_key: '2cc8c146efd02921cb56e9cf9af78b8d'
			}
		} );
		document.getElementById( 'apple_music_form' ).addEventListener( 'submit', function ( e ) {
			e.preventDefault();

			const email = document.getElementById( 'apple_music_email_address' ).value;

			sm.doActions( email, true ).then( function ( res ) {
				$.magnificPopup.close();
				$.magnificPopup.open( {
					items: {
						src: '#thanks',
						type: 'inline'
					}
				} );
			} ).catch( function ( err ) {
				alert( 'An error has occurred!' );
			} );
		} );
	}
} );

$( document ).ready( function ( $ ) {
	AOS.init( {
		duration: 1000
	} );

	$( '#image' ).on( 'change', function ( e ) {
		const img = $( this )[0];
		if ( img.files && img.files[0] ) {
			const reader = new FileReader();
			reader.onload = function ( e ) {
				$( '#preview' ).css( 'background-image', 'url(' + e.target.result + ')' );
			};
			reader.readAsDataURL( img.files[0] );
		}
	} );

	$( '.share a' ).on( 'click', function ( e ) {
		e.preventDefault();
		const href = $( this ).attr( 'href' );
		popupWindow( href, 'Share', window, 670, 378 );
	} );

	$( '#ugc-form' ).on( 'submit', function ( e ) {
		e.preventDefault();

		$.ajax( {
			url: ajax_object.ajax_url,
			method: 'POST',
			data: new FormData( this ),
			dataType: 'json',
			processData: false,
			contentType: false,
			beforeSend: function () {
				$( '#ugc-form button' ).prop( 'disabled', true ).text( 'Loading...' );
			},
			complete: function () {
				$( '#ugc-form button' ).prop( 'disabled', false ).text( 'Post Your Image To The Yearbook' );
			},
			success: function ( response, textStatus, jqXHR ) {
				console.log( response );
				if ( response.data.success ) {
					$( '#ugc-form' ).remove();
					$( '.success' ).show();
					$( 'a.facebook' ).attr( 'href', 'https://www.facebook.com/sharer/sharer.php?u=' + response.data.url );
					$( 'a.twitter' ).attr( 'href', 'https://twitter.com/intent/tweet?text=&url=' + response.data.url );
					$( 'a.mail' ).attr( 'href', 'mailto:?subject=KIITA%20Yearbook&body=' + response.data.url );
					$( '.form-wrap #message' ).html( response.data.message + '<br>' + 'URL: <a href="' + response.data.url + '">' + response.data.url + '</a>' );
				} else {
					alert( response.data.message );
				}
			},
			error: function ( jqXHR, textStatus, errorThrown ) {
				alert( 'An error has occurred!' );
			}
		} );
	} );

	$( '#newsletter form' ).on( 'submit', function ( e ) {
		e.preventDefault();
		const data = $( this ).serialize();
		$.ajax( {
			type: 'POST',
			url: 'https://subs.sonymusicfans.com/submit',
			dataType: 'json',
			data: data,
			xhrFields: {
				withCredentials: false
			},
			success: function ( data ) {
				$( 'form' ).html( '<div class="thanks">Thanks for signing up!</div> ' );
			},
			error: function ( err ) {
				alert( 'An error has occurred!' );
			}
		} );
	} );

	function popupWindow( url, title, win, w, h ) {
		const y = win.top.outerHeight / 2 + win.top.screenY - (
			h / 2
		);
		const x = win.top.outerWidth / 2 + win.top.screenX - (
			w / 2
		);
		return win.open( url, title, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}` );
	}

	$( '.btn-apple' ).on( 'click', function ( e ) {
		e.preventDefault();
		$.magnificPopup.open( {
			items: {
				src: '#apple',
				type: 'inline'
			}
		} );
	} );

	let SMEPreSave = {
		state: null,
		spotify_user: null,
		url_vars: [],
		getURLVars: function ( callback, form_id ) {
			let hash;
			let hashes = window.location.href.slice( window.location.href.indexOf( '?' ) + 1 ).split( '&' );
			for ( let i = 0; i < hashes.length; i ++ ) {
				hash = hashes[i].split( '=' );
				this.url_vars.push( hash[0] );
				this.url_vars[hash[0]] = hash[1];
			}
			this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
			this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
			this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
			this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
			this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;
			if ( typeof callback === 'function' ) {
				callback( this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message );
			}
		}
	};

	SMEPreSave.getURLVars( function ( state, ae_member_id, spotify_user, deezer_user, message ) {
		// Check the state of the pre-save. If it was successful, the state will either be success or thank-you.
		if ( state === 'success' || state === 'thank-you' ) {
			$.magnificPopup.open( {
				items: {
					src: '#thanks',
					type: 'inline'
				}
			} );
		}
	} );

} );